<template>
  <router-view/>
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
  setup() {
    // eslint-disable-next-line no-unused-vars
    const debounce = (callback: (...args: any[]) => void, delay: number) => {
      let tid: any;
      return function (...args: any[]) {
        const ctx = self;
        if (tid) {
          clearTimeout(tid);
        }
        tid = setTimeout(() => {
          callback.apply(ctx, args);
        }, delay);
      };
    };

    const OriginalResizeObserver = (window as any).ResizeObserver;
    (window as any).ResizeObserver = class ResizeObserver extends OriginalResizeObserver {
      // eslint-disable-next-line no-unused-vars
      constructor(callback: (...args: any[]) => void) {
        const debouncedCallback = debounce(callback, 20);
        super(debouncedCallback);
      }
    };
  }
});
</script>

<style>

</style>
