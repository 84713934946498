export const openWindows = async (imagePaths: any) => {
    let pathsArray: string[];

    const checkFileType = async (paths: string[]): Promise<any[]> => {
        const requests = paths.map(url => fetch(url, {method: 'HEAD'}));

        try {
            const responses = await Promise.all(requests);
            return responses.map(resp => ({
                url: resp.url,
                type: resp.headers.get('Content-Type')
            }));
        } catch (error) {
            console.error("Failed to fetch image types:", error);
            return [];
        }
    }

    if (typeof imagePaths === 'string') {
        try {
            pathsArray = JSON.parse(imagePaths);
            if (!Array.isArray(pathsArray)) {
                throw new Error("Parsed value is not an array");
            }
        } catch (error) {
            pathsArray = [imagePaths];
        }
    } else if (Array.isArray(imagePaths)) {
        pathsArray = imagePaths;
    } else {
        console.error("Invalid image paths format.");
        return;
    }

    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const length = 8;
    let result = '';
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
    }

    const prefixedPathsArray = pathsArray.map(path => "https://hrms21.s3.ap-southeast-1.amazonaws.com/" + path + "?s=" + result);
    const fileTypeInfo = await checkFileType(prefixedPathsArray);

    const pdfs = fileTypeInfo.filter(info => info.type === 'application/pdf');
    const images = fileTypeInfo.filter(info => info.type.startsWith('image/'));

    const pdfsHTML = pdfs.map(pdf =>
        `<iframe src="${pdf.url}" style="width:100%; height:600px;" frameborder="0"></iframe>`
    ).join('');

    const imagesHTML = images.map(image =>
        `<img src="${image.url}" alt="Image" style="max-width:100%; max-height:600px; margin-bottom:10px;">`
    ).join('');

    const htmlContent = `
        <!DOCTYPE html>
        <html>
        <head>
            <title>Attachments</title>
            <style>
                body {
                    margin: 0;
                    padding: 0;
                }
                #imageContainer {
                    display: flex;
                    flex-direction: column;
                    max-height: 100%;
                    overflow-y: auto;
                    justify-content: center;
                    align-items: center;
                    padding: 20px;
                }
                #contentContainer iframe,
                #imageContainer img {
                    width: auto;
                    height: auto;
                    max-width: 50%;
                    max-height: 50%;
                    margin-bottom: 10px;
                }
            </style>
        </head>
        <body> 
            <div id="imageContainer">
                ${pdfsHTML}
                ${imagesHTML}
            </div>
        </body>
        </html>
    `;

    const newWindow = window.open(
        '',
        '_blank',
        `scrollbars=no,
        resizable=no,
        status=no,
        location=no,
        toolbar=no,
        menubar=no,
        height=1600,
        width=1200,
        top=100`);
    if (newWindow) {
        newWindow.document.write(htmlContent);
        newWindow.document.close();
    } else {
        console.error("Failed to open new window.");
    }
}
