import { createApp } from "vue";
import App from "./App.vue";
import "element-plus/dist/index.css";
import { createPinia } from "pinia";

import ElementPlus from "element-plus";
import router from "./router";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import mitt, { Emitter } from "mitt";
import ECharts from "vue-echarts";
// @ts-ignore
import { use } from "echarts/core";
// @ts-ignore
import { SetupCalendar } from "v-calendar";
import VCalendar from "v-calendar";
import "v-calendar/style.css";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart, PieChart } from "echarts/charts";
import { DatasetComponent, GridComponent, LegendComponent, TitleComponent, ToolboxComponent, TooltipComponent, TransformComponent } from "echarts/components";
import { openWindows } from "@/utils/WindowUtils";
import VueGoogleMaps from "@fawmi/vue-google-maps";

// 导入公共样式
import "./style/index.scss";
// @ts-ignore
declare module "vue/types/vue" {
  interface Vue {
    $file: string;
    mitter: Emitter<any>;
    $openWindows: Function;
  }
}

const mitter: Emitter<any> = mitt();

const app = createApp(App);

use([CanvasRenderer, BarChart, GridComponent, TooltipComponent, TitleComponent, LegendComponent, PieChart, ToolboxComponent, DatasetComponent, TransformComponent]);

export const formatDate = (date: Date, format = "yyyy-MM-dd HH:mm:ss"): string => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();
  const formatMap: { [key: string]: any } = {
    yyyy: year.toString(),
    MM: month.toString().padStart(2, "0"),
    dd: day.toString().padStart(2, "0"),
    HH: hour.toString().padStart(2, "0"),
    mm: minute.toString().padStart(2, "0"),
    ss: second.toString().padStart(2, "0"),
  };
  return format.replace(/yyyy|MM|dd|HH|mm|ss/g, (match) => formatMap[match]);
};

app
  .use(router)
  .use(createPinia())
  .use(ElementPlus)
  .use(VCalendar)
  .use(SetupCalendar, {})
  .component("v-chart", ECharts)
  .provide("mitter", mitter)
  .use(VueGoogleMaps, {
    load: {
      key: "AIzaSyBq40lSgs8b4ed4a5LVfFfvlHtK3bWWuzc",
    },
  })
  .mount("#app");

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
app.config.globalProperties.mitter = mitter;
app.config.globalProperties.$openWindows = openWindows;
app.config.globalProperties.$file = "https://hrms21.s3.ap-southeast-1.amazonaws.com/";
